<template>
    <div>
        <div class="head-container">
            <div>
                <div class="head-text-main">
                    Relevant Projects
                </div>
            </div>
        </div>
        <v-container class="main-text text-container">
            <v-row>
                <v-col>
                    <h2 class="mt-8 mb-4"><strong>The development of the DSS NUTRISENSE was supported by the following research projects:</strong></h2>
                    <p>
                        “<strong>NUTRISENSE</strong> Development of an innovative technology using special ion electrodes and suitable software for hydroponic production with emphasis on recycling of the drainage solution in closed systems”. Project sponsored by the Hellenic Foundation for Research and Innovation.
                    </p>

                    <p>
                        “<strong>VEGADAPT</strong> Adapting Mediterranean vegetable crops to climate change-induced multiple stress”. Project sponsored by the PRIMA Foundation (<a href="https://www.veg-adapt.unito.it" target="_blank">https://www.veg-adapt.unito.it</a>).
                    </p>
                    <p>
                        “<strong>ECONUTRI</strong>. Innovative concepts and technologies for ECOlogically sustainable NUTRIent management in agriculture aiming to prevent, mitigate and eliminate pollution in soils, water, and air”. Project sponsored by the European Commission within the framework of HORIZON Europe (<a href="https://econutri-project.eu/" target="_blank">https://econutri-project.eu/</a>).
                    </p>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
    export default {
        name: 'App',
        components: {
        },
        data: () => ({
        }),
        computed: {
        }
    }
</script>
<style scoped>
.heading {
    color: #5FA936;
    font-size: 36px;
    font-weight: 700;
}

.head-container{
    background-image: url("~@/assets/about_a_head.png");
    height: 380px;
    width: 100%;
    z-index: 0;
    color: #fff;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-shadow: 0px 0px 20px #0304036b;
    margin-top: -50px;
}

.head-text-main {
    font-weight: 700;
    font-size: 96px;
}

.head-text-secondary {
    font-size: 32px;
}

.main-text {
    color: #646464;
    font-size: 16px;
    line-height: 26px;
}
.text-container {
    max-width: 65vw;
}

.green-text {
    color: #006600;
    font-weight: bold;
}
ol {
    color: #383838;
    font-weight: bold;
}
.spacer {
    display: inline-block;
    width: 40px;
}
strong {
    color: #383838;
}
</style>
